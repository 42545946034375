import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StateProvider } from './state';
import { initialState, reducer } from './reducer';
import logger from './logger';
import AppProviders from './context';

ReactDOM.render(
    <StateProvider
        initialState={initialState}
        reducer={
            process.env.NODE_ENV === 'development' ? logger(reducer) : reducer
        }
    >
        <AppProviders>
            <App />
        </AppProviders>
    </StateProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { createContext, useContext, useReducer } from 'react';

export const StateContext = createContext<any>({});

// const stateString = localStorage.getItem('state');
// const localState = stateString && JSON.parse(stateString);

export const StateProvider = ({ reducer, initialState, children }: any) => {
    let [state, dispatch] = useReducer(reducer, initialState);

    // useEffect(() => {
    //     localStorage.setItem('state', JSON.stringify(state));
    // }, [state]);

    return (
        <StateContext.Provider value={[state, dispatch]}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateValue = () => useContext(StateContext);

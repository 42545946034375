import axios from 'axios';

interface Headers {
    'content-type': string;
    Authorization?: string;
}

const API_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_URL
        : '/api';

const SITE_API_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_SITE_API_URL
        : '/site-api';

const AUTH_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_AUTH_API_URL
        : '/auth/' + process.env.REACT_APP_CONFIG_VARIANT;

const AIRCRAFT_MEDIA_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_AIRCRAFT_MEDIA_API_URL
        : '/aircraft';

function getApiRoot(root: string) {
    switch (root) {
        case 'auth':
            return AUTH_ROOT;
        case 'aircraftMedia':
            return AIRCRAFT_MEDIA_ROOT;
        case 'orders':
            return SITE_API_ROOT;
        default:
            return API_ROOT;
    }
}

function client(
    endpoint: string,
    { body, method, root, ...customConfig }: any = {}
) {
    const token = window.localStorage.getItem('__najet_token__');
    const headers: Headers = { 'content-type': 'application/json' };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    const config = {
        method: method || body ? 'post' : 'get',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers
        }
    };
    if (body) {
        config.data = JSON.stringify(body);
    }
    const apiRoot = getApiRoot(root);
    return axios(`${apiRoot}/${endpoint}`, config).then((r) => r.data);
}

export default client;

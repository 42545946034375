import React from 'react';

const wrapperStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const loaderStyle: React.CSSProperties = {
    fontSize: 18,
    color: '#7c7c7c',
    fontWeight: 'bold'
};

export default () => (
    <div style={wrapperStyle}>
        <div style={loaderStyle}>Загрузка...</div>
    </div>
);

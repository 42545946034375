import { airports } from './data_from_1c';


interface Airport {
    type: 'airport';
    name: string;
    nameEn: string;
    city: string;
    cityEn: string;
    country: string;
    countryEn: string;
    icao: string;
    iata: string;
    timezone: string;
    utcOffset: string;
}

export interface AirportWithTitle extends Airport {
    title: string;
}

interface City {
    type: 'city';
    city: string;
    timezone: string;
}

interface CityWithTitle extends City {
    title: string;
}

export type AirportOrCity = AirportWithTitle | CityWithTitle;

export const airportsWithCities = (airports as (Airport | City)[]).concat([
    {
        type: 'city',
        city: 'Москва',
        timezone: 'Europe/Moscow'
    },
    { type: 'city', city: 'Лондон', timezone: 'Europe/London' }
]);

export const airportsWithTitles: AirportOrCity[] = airportsWithCities.map((item) => ({
    ...item,
    title: generateTitle(item)
}));

function generateTitle(item: any) {
    return item.type === 'airport'
        ? item.city !== item.name
            ? `${item.city} ${item.name} (${item.icao} ${item.iata})`
            : `${item.city} (${item.icao} ${item.iata})`
        : `${item.city} (Любой)`;
}

export const findByTitle = (title: string) => {
    return airportsWithTitles.find((item: any) => item.title === title);
};

export const findByICAO = (airport: string) =>
    airportsWithTitles.find((item: any) => item.icao === airport);

import client from './apiClient';

const localStorageKey = '__najet_token__';

function handleUserResponse({ token, user }: any) {
    window.localStorage.setItem(localStorageKey, token);
    return user;
}

function getUser() {
    const token = getToken();
    if (!token) {
        return Promise.resolve(null);
    }
    return client('me', { root: 'auth' }).catch((error) => {
        logout();
        return Promise.reject(error);
    });
}

function login({ username, password }: any) {
    return client('login', { root: 'auth', body: { username, password } }).then(
        handleUserResponse
    );
}

function logout() {
    window.localStorage.removeItem(localStorageKey);
    return Promise.resolve();
}

function getToken() {
    return window.localStorage.getItem(localStorageKey);
}

export { login, logout, getToken, getUser };

import React from 'react';
import { AuthProvider } from './authContext';
import { UserProvider } from './userContext';

function AppProviders({ children }: any) {
    return (
        <AuthProvider>
            <UserProvider>{children}</UserProvider>
        </AuthProvider>
    );
}

export default AppProviders;

import { Card, Order } from './interfaces';
import { findByICAO, AirportOrCity } from './airports';
import { Airplanes } from './airplanes';
import {
    SearchFlightsParams,
    getFlight as getFlightApi,
    searchFlights as searchFlightsApi,
    getSavedSearchResult as getSavedSearchResultApi,
    orderFlight as orderFlightApi
} from './utils/flightsClient';

export type SortFlightsBy = 'time' | 'price' | 'seats';

export interface State {
    [key: string]: any;
    searchParams: {
        arrivalAirport: AirportOrCity | null;
        departureAirport: AirportOrCity | null;
        departureAirplane: Airplanes | null;
        departureDate: string | null;
        pax: number;
        departureDateBack: string | null;
        paxBack: number;
        backFlight: boolean;
    };
    loading: boolean;
    loadingText: string;
    currency: string;
    selectedFlight: Card | null;
    flights: Card[] | null;
    searchResultDate: string | null;
    searchId: string | null;
    sortFlightsBy: SortFlightsBy;
    orders: Order[] | null;
    shared: boolean;
    scrollPosition: number;
}

export const initialState: State = {
    searchParams: {
        arrivalAirport: null,
        departureAirport: null,
        departureDate: null,
        departureAirplane: null,
        pax: 1,
        backFlight: false,
        departureDateBack: null,
        paxBack: 1
    },
    loading: false,
    loadingText: '',
    currency: localStorage.getItem('currency') || 'eur',
    selectedFlight: null,
    flights: null,
    searchId: null,
    searchResultDate: null,
    sortFlightsBy: 'time',
    orders: null,
    shared: false,
    scrollPosition: 0
};

interface Action {
    type: string;
    payload?: any;
}

export const reducer = (state: any, action: Action) => {
    switch (action.type) {
        case 'resetState':
            localStorage.removeItem('state');
            return initialState;
        case 'searchFlightsFetch':
            return {
                ...state,
                loadingText: 'Поиск рейсов',
                loading: true,
                flights: null,
                selectedFlight: null,
                searchResultDate: null,
                searchId: null
            };
        case 'searchFlightsSuccess':
            return {
                ...state,
                loading: false,
                flights: action.payload.cards,
                searchResultDate: action.payload.datetime,
                searchId: action.payload.id,
                shared: false
            };
        case 'searchFlightsError':
            return {
                ...state,
                loading: false,
                flights: null
            };
        case 'updateSearchParams':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    ...action.payload
                }
            };
        case 'selectFlight':
            return {
                ...state,
                selectedFlight: action.payload.flight,
                scrollPosition: action.payload.scrollPosition
            };
        case 'backToSearch':
            const orderStatus = state.orders
                ? 'booked'
                : state.selectedFlight
                ? state.selectedFlight.status || 'not_booked'
                : 'not_booked';
            return orderStatus !== 'not_booked'
                ? initialState
                : {
                      ...state,
                      selectedFlight: null
                  };
        case 'orderFlightFetch':
            return {
                ...state,
                loading: true,
                loadingText: 'Бронирование рейса'
            };
        case 'orderFlightSuccess':
            return {
                ...state,
                loading: false,
                orders: action.payload.orders
            };
        case 'orderFlightError':
            return {
                ...state,
                loading: false
            };
        case 'changeSortFlightsBy':
            return {
                ...state,
                sortFlightsBy: action.payload
            };
        case 'getSavedSearchResultFetch':
            return {
                ...initialState,
                loading: true,
                loadingText: 'Получение результатов поиска'
            };
        case 'getSavedSearchResultError':
            return {
                ...initialState,
                loading: false
            };
        case 'getSavedSearchResultSuccess':
            const {
                id,
                cards,
                datetime,
                query: {
                    departure_date_there,
                    pax_there,
                    departure_date_back,
                    pax_back,
                    departure_airport,
                    arrival_airport
                }
            } = action.payload;

            return {
                ...initialState,
                searchId: id,
                loading: false,
                shared: true,
                searchResultDate: datetime,
                searchParams: {
                    departureDate: departure_date_there,
                    departureAirport: findByICAO(departure_airport),
                    arrivalAirport: findByICAO(arrival_airport),
                    pax: pax_there,
                    backFlight: !!departure_date_back,
                    departureDateBack: departure_date_back,
                    paxBack: pax_back
                },
                flights: cards
            };
        case 'getFlightFetch':
            return {
                ...state,
                loading: true,
                loadingText: 'Получение информации о рейсе'
            };
        case 'getFlightError':
            return {
                ...state,
                loading: false
            };
        case 'getFlightSuccess':
            return {
                ...state,
                loading: false,
                ordered: action.payload.order_status === 'booked',
                selectedFlight: action.payload
            };
        case 'setCurrency':
            localStorage.setItem('currency', action.payload);
            return {
                ...state,
                currency: action.payload
            };
        default:
            throw new Error(`unknown action type: ${action.type}`);
    }
};

export const searchFlights = async (
    params: SearchFlightsParams,
    dispatch: any
) => {
    dispatch({ type: 'updateSearchParams', payload: params });
    dispatch({ type: 'searchFlightsFetch' });
    const data = await searchFlightsApi(params);
    if ('error' in data) {
        dispatch({ type: 'searchFlightsError', payload: data });
        return;
    }
    dispatch({ type: 'searchFlightsSuccess', payload: data });
};

export const updateSearchParams = (params: any, dispatch: any) => {
    dispatch({ type: 'updateSearchParams', payload: params });
};

export const orderFlight = async (flight_id: string, dispatch: any) => {
    dispatch({ type: 'orderFlightFetch' });
    const data = await orderFlightApi(flight_id);
    if (data) {
        const email_error_text = <HTMLInputElement>document.getElementById('error-text')!
        email_error_text.textContent = data;
    } else {
        window.location.reload();
    }
    dispatch({ type: 'orderFlightSuccess', payload: data });
    return data;
};

export const getSavedSearchResult = async (searchId: string, dispatch: any) => {
    dispatch({ type: 'getSavedSearchResultFetch' });
    const data = await getSavedSearchResultApi(searchId);
    if ('error' in data) {
        dispatch({ type: 'getSavedSearchResultError', payload: data });
        return;
    }
    dispatch({ type: 'getSavedSearchResultSuccess', payload: data });
    return data;
};

export const getFlight = async (flightId: string, dispatch: any) => {
    dispatch({ type: 'getFlightFetch' });
    const data = await getFlightApi(flightId);
    if ('error' in data) {
        dispatch({ type: 'getFlightError', payload: data });
        return;
    }
    dispatch({ type: 'getFlightSuccess', payload: data });
    return data;
};

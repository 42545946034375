import React, { useEffect } from 'react';

import { useUser } from './context/userContext';
import InitialLoader from './components/InitialLoader';

import './index.css';

const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));
const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));

const useScrollEffect = () => {
    useEffect(() => {
        window.onscroll = function() {
            var scrolled =
                window.pageYOffset || document.documentElement.scrollTop;
            if (scrolled > 0) {
                document.body.classList.add('scrolled');
            } else {
                document.body.classList.remove('scrolled');
            }
        };
    }, []);
};

export default () => {
    const user = useUser();
    useScrollEffect();
    return (
        <React.Suspense fallback={<InitialLoader />}>
            {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </React.Suspense>
    );
};

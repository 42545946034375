import React from 'react';
import { useAsync } from 'react-async';

import * as authClient from '../utils/authClient';
import { bootstrapAppData } from '../utils/bootstrap';
import InitialLoader from '../components/InitialLoader';

const AuthContext = React.createContext<any>(null);

function AuthProvider(props: any) {
    const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(
        false
    );
    const {
        data = { user: null },
        error,
        isRejected,
        isPending,
        isSettled,
        reload
    } = useAsync({
        promiseFn: bootstrapAppData
    });

    React.useLayoutEffect(() => {
        if (isSettled) {
            setFirstAttemptFinished(true);
        }
    }, [isSettled]);

    if (!firstAttemptFinished) {
        if (isPending) {
            return <InitialLoader />;
        }
        if (isRejected) {
            return (
                <div style={{ color: 'red' }}>
                    <p>Uh oh... There's a problem. Try refreshing the app.</p>
                    <pre>{error?.message}</pre>
                </div>
            );
        }
    }

    const login = (form: any) => authClient.login(form).then(reload);
    const logout = () => authClient.logout().then(reload);

    return <AuthContext.Provider value={{ data, login, logout }} {...props} />;
}

function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return context;
}

export { AuthProvider, useAuth };

import client from './apiClient';

import { Flight } from '../interfaces';
import { AirportOrCity } from '../airports';
import { Airplanes } from '../airplanes';

export interface SearchFlightsParams {
    departureDate: string;
    departureDateBack: string | null;
    departureAirport: AirportOrCity;
    arrivalAirport: AirportOrCity;
    departureAirplane: Airplanes | null;
    pax: number;
    paxBack: number;
    backFlight: boolean;
    operator: string | null;
    master_search: boolean;
}

interface ApiParams {
    departure_date_there: string;
    departure_date_back?: string;
    departure_airport?: string;
    arrival_airport?: string;
    departure_city?: string;
    arrival_city?: string;
    pax_there: number;
    pax_back?: number;
    operator?: string;
    master_search?: boolean;
    aircraft?: string;
}

export const searchFlights = async (
    params: SearchFlightsParams
): Promise<{ flights: Flight[] } | { error: any }> => {
    const apiParams: ApiParams = {
        departure_date_there: params.departureDate,
        departure_date_back: params.departureDateBack || undefined,
        [params.departureAirport.type === 'airport'
            ? 'departure_airport'
            : 'departure_city']:
            params.departureAirport.type === 'airport'
                ? params.departureAirport.icao
                : params.departureAirport.city,
        [params.arrivalAirport.type === 'airport'
            ? 'arrival_airport'
            : 'arrival_city']:
            params.arrivalAirport.type === 'airport'
                ? params.arrivalAirport.icao
                : params.arrivalAirport.city,
        pax_there: params.pax,
        pax_back: params.paxBack,
        operator: process.env.REACT_APP_CONFIG_VARIANT,
        master_search: params.master_search,
        aircraft: params.departureAirplane ? params.departureAirplane.name : undefined
    };

    try {
        return await client('cards', {
            params: apiParams
        });
    } catch (error) {
        return { error };
    }
};

export const getSavedSearchResult = async (searchId: string) => {
    try {
        return await client(`requests/${searchId}`);
    } catch (error) {
        return { error };
    }
};

export const getFlight = async (cardId: string) => {
    try {
        return await client(`cards/${cardId}`);
    } catch (error) {
        return { error };
    }
};

// process.env.REACT_APP_CONFIG_VARIANT
export const orderFlight = async (flightId: string) => {
    const params = new URLSearchParams(window.location.search)
    const email_input = <HTMLInputElement>document.getElementById('email')!
    try {
        return await client(`orders-1c`, {
            body: { card_id: flightId, customer: email_input.value || null }, root: 'orders'
        });
    } catch (error) {
        console.log(error);
        return error.response.data;
    }
};

export const getAircraftImages = async (aircraft: string) => {
    try {
        return await client(`images/${aircraft}`, { root: 'aircraftMedia' });
    } catch (error) {
        return { error };
    }
};

import { getUser } from './authClient';

async function bootstrapAppData() {
    const user = await getUser();
    if (!user) {
        return { user: null };
    }

    return {
        user
    };
}

export { bootstrapAppData };
